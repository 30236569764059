import React,{useState, useRef} from 'react';
import {Col,Form,Button} from 'react-bootstrap';

import _ from 'lodash';

import moment from 'moment';

const FormPatient = () => {

  const [validated, setValidated] = useState(false);
  const [under18, setUnder18] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const refEmail = useRef(0);
  const refCEmail = useRef(0);
  const refCGEmail = useRef(0);
  const refCCGEmail = useRef(0);
  const refPEmail = useRef(0);
  const refCPEmail = useRef(0);
  const refdob = useRef(0);

  const dobFormat = "MM/DD/YYYY";
  const dobPattern = "[0-9]{2}\/[0-9]{2}\/[0-9]{4}";

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (submitting) {
      event.preventDefault();
      event.stopPropagation();
    }
    setSubmitting(true);

    if(refEmail.current.value!=refCEmail.current.value ||
      (under18 && _.get(refCGEmail,'current.value','')!=_.get(refCCGEmail,'current.value','')) ||
      (!under18 && _.get(refPEmail,'current.value','')!=_.get(refCPEmail,'current.value','')) ||
      isDateInvalid()
      ){
      event.preventDefault();
      event.stopPropagation();
      setSubmitting(false);
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setSubmitting(false);
    }

    setValidated(true);
  };

  const isDateInvalid = () => {
    const dob = refdob.current.value;
    console.log(refdob);
    if('/'+dobPattern+'/'.test(dob)){
      const years = moment().diff(moment(dob, dobFormat), 'years');
      return ((under18 && years > 18) || (!under18 && years < 18));
    }
    return false;
  }

  const renderUnder18Elements = () => {
    return <><Form.Row>

      <Form.Group as={Col}>
        <Form.Label className="required">Caregiver First Name *</Form.Label>
        <Form.Control
          required
          type="text"
          name="caregiver-first-name"
          placeholder="Caregiver First Name"
        />
        <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label className="required">Caregiver Last Name *</Form.Label>
        <Form.Control
          required
          type="text"
          name="caregiver-last-name"
          placeholder="Caregiver Last Name"
        />
        <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
      </Form.Group>

    </Form.Row>

    <Form.Row>

      <Form.Group as={Col}>
        <Form.Label className="required">Caregiver Email Address *</Form.Label>
        <Form.Control
          required
          type="email"
          id="caregiverEmail"
          name="caregiverEmail"
          placeholder="Caregiver Email Address"
          ref={refCGEmail}
        />
        <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label className="required">Confirm Caregiver Email Address *</Form.Label>
        <Form.Control
          required
          type="email"
          id="confirmCaregiverEmail"
          name="confirmCaregiverEmail"
          placeholder="Confirm Caregiver Email Address"
          isInvalid={_.get(refCGEmail,'current.value','')!=_.get(refCCGEmail,'current.value','')}
          ref={refCCGEmail}
        />
        <Form.Control.Feedback type="invalid">Caregiver emails must match</Form.Control.Feedback>
      </Form.Group>

    </Form.Row>
    </>
  }


  const renderAbove18Elements = () => {
    return <><Form.Row>

      <Form.Group as={Col}>
        <Form.Label className="required">Patient Email Address *</Form.Label>
        <Form.Control
          required
          type="email"
          id="patientEmail"
          name="patientEmail"
          placeholder="Patient Email Address"
          ref={refPEmail}
        />
        <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label className="required">Confirm Patient Email Address *</Form.Label>
        <Form.Control
          required
          type="email"
          id="confirmPatientEmail"
          name="confirmPatientEmail"
          placeholder="Confirm Patient Email Address"
          isInvalid={_.get(refPEmail,'current.value','')!=_.get(refCPEmail,'current.value','')}
          ref={refCPEmail}
        />
        <Form.Control.Feedback type="invalid">Patient emails must match</Form.Control.Feedback>
      </Form.Group>

    </Form.Row>
    </>
  }

  let formUrl = '/api/docusign.php?action=envelope';


  return <Form noValidate validated={validated} onSubmit={handleSubmit} method="post" action={formUrl}>
  <Form.Row>

    <Form.Group as={Col}>
      <Form.Label className="required">Prescriber First Name *</Form.Label>
      <Form.Control
        required
        type="text"
        name="prescriber-first-name"
        placeholder="Prescriber First Name"
      />
      <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Col}>
      <Form.Label className="required">Prescriber Last Name *</Form.Label>
      <Form.Control
        required
        type="text"
        name="prescriber-last-name"
        placeholder="Prescriber Last Name"
      />
      <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
    </Form.Group>

  </Form.Row>

  <Form.Row>

    <Form.Group as={Col}>
      <Form.Label className="required">Prescriber Email Address *</Form.Label>
      <Form.Control
        required
        type="email"
        id="prescriberEmail"
        name="prescriberEmail"
        placeholder="Prescriber Email Address"
        ref={refEmail}
      />
      <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Col}>
      <Form.Label className="required">Confirm Prescriber Email Address *</Form.Label>
      <Form.Control
        required
        type="email"
        id="confirmPrescriberEmail"
        name="confirmPrescriberEmail"
        placeholder="Confirm Prescriber Email Address"
        isInvalid={refEmail.current.value!=refCEmail.current.value}
        ref={refCEmail}
      />
      <Form.Control.Feedback type="invalid">Prescriber emails must match</Form.Control.Feedback>
    </Form.Group>
  </Form.Row>

  <Form.Row>

    <Form.Group as={Col}>
      <Form.Label className="required">Patient First Name *</Form.Label>
      <Form.Control
        required
        type="text"
        name="patient-first-name"
        placeholder="Patient First Name"
      />
      <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Col}>
      <Form.Label className="required">Patient Last Name *</Form.Label>
      <Form.Control
        required
        type="text"
        name="patient-last-name"
        placeholder="Patient Last Name"
      />
      <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
    </Form.Group>

  </Form.Row>

  <Form.Row>

    <Form.Group as={Col} md={6}>
      <Form.Label className="required">Patient Date of Birth *</Form.Label>
      <Form.Control
        required
        type="text"
        name="patientDob"
        placeholder={dobFormat}
        pattern={dobPattern}
        ref={refdob}
        isInvalid={isDateInvalid()}
      />
      <Form.Control.Feedback type="invalid">This field is required in format of {dobFormat} and is {under18 ? 'under 18 yrs' : 'above 18 yrs'}.</Form.Control.Feedback>
    </Form.Group>

  </Form.Row>

  <Form.Row>

    <Form.Group as={Col}>
      <Form.Label className="required mr-2">Is this a pediatric patient (under the age of 18)? *</Form.Label>
      <Form.Check
        inline
        type="radio"
        name="patient-under-eighteen"
        label="Yes"
        value="1"
        defaultChecked={under18}
        onClick={()=>setUnder18(true)}
      />
      <Form.Check
        inline
        type="radio"
        name="patient-under-eighteen"
        label="No"
        value="0"
        defaultChecked={!under18}
        onClick={()=>setUnder18(false)}
      />
    </Form.Group>

  </Form.Row>

  {under18 && renderUnder18Elements()}
    {!under18 && renderAbove18Elements()}




  <div className="text-center mt-3"><Button variant="dark" type="submit">Continue Online Enrollment</Button></div>

</Form>
}

export default FormPatient;
