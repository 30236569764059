import React,{useState, useRef} from 'react';
import {Row,Col,Form,Button} from 'react-bootstrap';

const FormPrescriber = () => {

  const [validated, setValidated] = useState(false);

  const refEmail = useRef(0);
  const refCEmail = useRef(0);

  const handleSubmit = (event) => {
    const form = event.currentTarget;


    if(refEmail.current.value!=refCEmail.current.value){
      event.preventDefault();
      event.stopPropagation();
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  let formUrl = '/api/forms.php?action=prescriber_enrollment&returl=/%23pren-feedback';

  return <Form noValidate validated={validated} onSubmit={handleSubmit} method="post" action={formUrl}>
  <Form.Row>

    <Form.Group as={Col} controlId="fname">
      <Form.Label className="required">Prescriber First Name *</Form.Label>
      <Form.Control
        required
        type="text"
        name="prescriber-first-name"
        placeholder="Prescriber First Name"
      />
      <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Col} controlId="lname">
      <Form.Label className="required">Prescriber Last Name *</Form.Label>
      <Form.Control
        required
        type="text"
        name="prescriber-last-name"
        placeholder="Prescriber Last Name"
      />
      <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
    </Form.Group>

  </Form.Row>

  <Form.Row>

    <Form.Group as={Col} controlId="email">
      <Form.Label className="required">Prescriber Email Address *</Form.Label>
      <Form.Control
        required
        type="email"
        id="prescriberEmail"
        name="prescriberEmail"
        placeholder="Prescriber Email Address"
        ref={refEmail}
      />
      <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
    </Form.Group>

    <Form.Group as={Col} controlId="lname">
      <Form.Label className="required">Confirm Prescriber Email Address *</Form.Label>
      <Form.Control
        required
        type="email"
        id="confirmPrescriberEmail"
        name="confirmPrescriberEmail"
        placeholder="Confirm Prescriber Email Address"
        isInvalid={refEmail.current.value!=refCEmail.current.value}
        ref={refCEmail}
      />
      <Form.Control.Feedback type="invalid">Prescriber emails must match</Form.Control.Feedback>
    </Form.Group>
  </Form.Row>

  <div className="text-center mt-3"><Button variant="dark" type="submit">Continue Online Enrollment</Button></div>

</Form>
}

export default FormPrescriber;
